#section-features {
	@include media($large-screen-down) {
		padding: 2em 0;
	}
}
.features {
	.title {
		color: $ourmid;
	}
	.feature-item {
		@include media($large-screen-up) {
			@include span-columns(4);
			@include omega(3n);	
		}
		@include media($large-screen-down) {
			@include span-columns(6);
			@include omega(2n);
		}
		@include media($small-screen-down) {
			@include span-columns(12);
			@include omega(1n);
		}
		padding: 1.5em 0;
		text-align: center;
		i {
			font-size: 2.8em;
			color: $highlight6;
			margin-bottom: .4em;
		}
		h4 {
			text-transform: uppercase;
			color: $ourmid;
			font-size: 1.2em;
		}
		p {
			color: $ourmid;
			font-size: 1em;
		}
	}
}