#section-contact {
	padding: 9em 0 3em;
	color: $ourlite;
	background-color: $ourmid;
	@include media($large-screen-down) {
		min-height: 0;
	}
	p {
		font-weight: 300;
	}
}
.contact {
	.title {
		text-align: left;
		margin-bottom: 0;
		@include media($large-screen-down) {
			text-align: center;
		}
		h2 {
			padding-bottom: .4em;
		}
	}
	.form {
		@include clearfix;
		.form-left {
			@include span-columns(6);
			padding-right: 1.65em;
			font-size: 1.4em;
			line-height: 1.33em;
			@include media($large-screen-down) {
				@include span-columns(12);
				@include pad(0 5%);
				text-align: center;
			}
			@include media($medium-screen-down) {
				font-size: 1.2em;
			}
		}
		.form-right {
			@include span-columns(6);
			@include media($large-screen-down) {
				@include span-columns(12);
				@include pad(0 5%);
			}
		}
		label {
			display: none;
		}
		input {
			height: 3em;
			margin-bottom: 1em;
			padding: 0 1em;
		}
		textarea {
			padding: 1em;
			height: 12em;
		}
	}
	p.submit {
		text-align: center;
		display: block;
		input {
			background: $highlight2;
			width: 160px;
			height: 50px;
			text-transform: uppercase;
			&:hover {
				background: lighten($highlight2, 5%);
			}
		}
	}
	.form-feedback {
		padding: 1em 2em;
		color: white;
	}
	.form-feedback.form-success {
		background-color: #2ecc71;
	}
	.form-feedback.form-error {
		background-color: #e74c3c;
	}
	#contact-error {
		margin: 0 20% 2em;

		@include media($medium-screen-down) {
			margin: 0 5% 2em;
		}
	}
}

#section-social-contacts {
	padding: 3.35em 0;
}

.social-contacts {
	color: $ourlite;
	background-color: $highlight;
	font-family: $cursive;
	p {
		font-size: 1.7em;
		color: lighten($highlight, 40%);
		text-align: center;
		margin-bottom: 0;
	}
}