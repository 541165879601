@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

a.button,
button,
input[type="submit"] {
  appearance: none;
  background-color: #f25022;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  a.button:hover, a.button:focus,
  button:hover,
  button:focus,
  input[type="submit"]:hover,
  input[type="submit"]:focus {
    background-color: #c2401b;
    color: #fff; }
  a.button:disabled,
  button:disabled,
  input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    a.button:disabled:hover,
    button:disabled:hover,
    input[type="submit"]:disabled:hover {
      background-color: #f25022; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1em; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple] {
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple]:hover {
    border-color: #b1b1b1; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple]:focus {
    border-color: #f25022;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(237, 63, 14, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple]:disabled:hover {
      border: 1px solid #DDD; }

textarea {
  resize: vertical; }

[type="search"] {
  appearance: none; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  max-width: 100%;
  width: auto; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #DDD;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  color: #999;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.333em;
  line-height: 1.25em;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #f25022;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #b63c1a; }

hr {
  border-bottom: 1px solid #DDD;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

img {
  margin: 0;
  max-width: 100%; }

blockquote {
  border-left: 2px solid #DDD;
  color: #bfbfbf;
  margin: 1.5em 0;
  padding-left: 0.75em;
  line-height: 1.5em; }

cite {
  color: #d9d9d9;
  font-style: italic; }
  cite:before {
    content: '\2014 \00A0'; }

/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel .owl-refresh .owl-item {
  display: none; }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img {
  transform-style: preserve-3d; }

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1; }

/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none; }

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
  font-family: 'flexslider-icon';
  src: url("fonts/flexslider-icon.eot");
  src: url("fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("fonts/flexslider-icon.woff") format("woff"), url("fonts/flexslider-icon.ttf") format("truetype"), url("fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0; }

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block; }

.flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block; }

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin: 0 0 60px;
  position: relative;
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/* Direction Nav */
.flex-direction-nav {
  *height: 0; }

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease; }

.flex-direction-nav .flex-prev {
  left: -50px; }

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right; }

.flexslider:hover .flex-prev {
  opacity: 0.7;
  left: 10px; }

.flexslider:hover .flex-next {
  opacity: 0.7;
  right: 10px; }

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
  opacity: 1; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '\f001'; }

.flex-direction-nav a.flex-next:before {
  content: '\f002'; }

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }

.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004'; }

.flex-pauseplay a:hover {
  opacity: 1; }

.flex-pauseplay a.flex-play:before {
  content: '\f003'; }

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: center; }

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }

.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7); }

.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer; }

.flex-control-thumbs img:hover {
  opacity: 1; }

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

/* 
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: white;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: white;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

/* DAP stuff */
/* typography */
body {
  font-family: 'Montserrat', sans-serif;
  color: #ededf0; }

h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase; }

p.lead {
  font-size: 1.5em;
  color: #90b1c8;
  font-family: "Coda", cursive; }

blockquote {
  font-style: italic;
  color: #b3b3b3;
  font-size: 1.1em; }
  blockquote p {
    margin: 0; }

a {
  color: #21c4ab; }
  a:hover {
    color: #1dae98; }

input,
textarea,
button {
  font-family: "Montserrat", sans-serif !important; }

input[type="text"],
input[type="email"],
textarea {
  color: #999999;
  border: solid 2px #ebebeb !important;
  box-shadow: none !important; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #bfbfbf; }
  input[type="text"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #bfbfbf; }
  input[type="text"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #bfbfbf; }
  input[type="text"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #bfbfbf; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    border: solid 2px #cccccc !important; }

/* layout */
.container {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 .5em; }
  .container::after {
    clear: both;
    content: "";
    display: table; }

html, body, section.intro, .split, .split-text {
  height: 100%; }

section {
  padding: 4em 0;
  position: relative; }

.serif {
  font-family: "Roboto Slab", serif; }

em {
  color: #21c4ab;
  font-family: "Roboto Slab", serif; }

code {
  color: #93adc0;
  font-family: "Share Tech Mono", monospace;
  font-size: 1.1em; }

kbd {
  color: #ffc929;
  font-family: "Coda", cursive;
  font-size: 1.1em; }

.centered {
  text-align: center; }

.no-transformation {
  text-transform: none; }

.italic {
  font-style: italic; }

.monospace {
  font-family: "Share Tech Mono", monospace; }

.color-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #21c4ab;
  z-index: -100; }

.dark {
  background-color: #2d2d2d; }

.mid {
  background-color: #324655; }

.lite {
  background-color: #ededf0; }

.highlight {
  background-color: #21c4ab; }

.deemphasize {
  font-size: 0.9em; }

.superscript {
  position: relative;
  font-size: 50%;
  max-width: 5%;
  vertical-align: text-top;
  top: 0;
  margin-top: 0;
  padding-top: 0; }

/* title section */
.title {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 2em;
  color: #ededf0; }
  .title h2 {
    text-transform: none; }
  .title hr {
    border: solid 2px rgba(0, 0, 0, 0.1);
    margin: 1em auto 1.5em; }
    .title hr.small {
      width: 50px; }
  .title p {
    opacity: 0.5;
    font-size: 1.25em; }

/* buttons */
a.button.large {
  color: #477DCA;
  background-color: #ededf0;
  text-transform: uppercase;
  text-align: center;
  padding: 1em 0.2em;
  margin-top: 1em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 1em;
  width: 200px;
  height: 50px; }
  a.button.large:hover {
    background: #bbcbe5; }

a.button.alt,
a.button.large.alt {
  color: #ededf0;
  background-color: #f25022; }
  a.button.alt:hover,
  a.button.large.alt:hover {
    background: #f57652; }

/* owl carousel styles */
.owl-theme .owl-dots .owl-dot span {
  height: 14px;
  width: 14px; }

.owl-theme .owl-nav [class*='owl-'] {
  padding-top: 17px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px; }

.owl-carousel {
  cursor: move; }

/* flexslider */
.flexslider {
  margin: 0; }

.flex-control-nav {
  bottom: 0;
  z-index: 100; }
  .flex-control-nav li a {
    background: white;
    opacity: 0.5;
    height: 14px;
    width: 14px;
    box-shadow: none; }
    .flex-control-nav li a:hover {
      opacity: 0.7; }
    .flex-control-nav li a.flex-active {
      background: white;
      opacity: 1; }

.flex-direction-nav a {
  height: 52px;
  width: 52px;
  top: 45%;
  font-weight: bold;
  box-shadow: none;
  text-shadow: none;
  color: white; }
  .flex-direction-nav a:before {
    font-family: 'Fontawesome';
    font-size: 3em;
    display: inline-block;
    content: '\f104'; }
  .flex-direction-nav a.flex-next:before {
    content: '\f105'; }

header.navigation.headroom--unpinned {
  transform: translateY(-100%); }

header.navigation.headroom--pinned {
  transform: translateY(0%); }

header.navigation.headroom {
  position: fixed;
  background-color: #2d2d2d;
  border-bottom: 1px solid #141414;
  height: 6em;
  width: 100%;
  z-index: 999;
  transition: transform 200ms linear; }
  header.navigation.headroom .navigation-wrapper {
    max-width: 68em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 9999; }
    header.navigation.headroom .navigation-wrapper::after {
      clear: both;
      content: "";
      display: table; }
    header.navigation.headroom .navigation-wrapper::after {
      clear: both;
      content: "";
      display: table; }
  header.navigation.headroom .logo {
    float: left;
    max-height: 6em;
    padding-left: 1em;
    padding-right: 1em; }
    header.navigation.headroom .logo img {
      max-height: 6em;
      padding: 1em 0; }
  header.navigation.headroom .navigation-menu-button {
    color: #ededf0;
    display: block;
    float: right;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    line-height: 6em;
    margin: 0;
    padding-right: 1em;
    text-transform: uppercase; }
    @media screen and (min-width: 54.375em) {
      header.navigation.headroom .navigation-menu-button {
        display: none; } }
    header.navigation.headroom .navigation-menu-button:hover {
      color: #f25022; }
  header.navigation.headroom .nav {
    z-index: 9999999;
    float: none; }
    @media screen and (min-width: 54.375em) {
      header.navigation.headroom .nav {
        float: right; } }
  header.navigation.headroom ul#navigation-menu {
    -webkit-transform-style: preserve-3d;
    clear: both;
    display: none;
    margin: 0 auto;
    overflow: visible;
    padding: 0;
    width: 100%;
    z-index: 9999; }
    @media screen and (min-width: 54.375em) {
      header.navigation.headroom ul#navigation-menu {
        display: inline;
        margin: 0;
        padding: 0; } }
  header.navigation.headroom ul li.nav-link {
    background: #2d2d2d;
    display: block;
    line-height: 4em;
    overflow: hidden;
    padding-left: 0.4em;
    text-align: right;
    width: 100%;
    z-index: 9999; }
    @media screen and (max-width: 54.375em) {
      header.navigation.headroom ul li.nav-link {
        padding-left: 0;
        text-align: center;
        border-bottom: solid 1px #202020; } }
    @media screen and (min-width: 54.375em) {
      header.navigation.headroom ul li.nav-link {
        background: transparent;
        display: inline;
        line-height: 6em;
        text-decoration: none;
        width: auto; } }
    header.navigation.headroom ul li.nav-link.active a {
      color: #21c4ab; }
    header.navigation.headroom ul li.nav-link a {
      display: inline-block;
      font-weight: 600;
      color: #ededf0; }
      @media screen and (min-width: 54.375em) {
        header.navigation.headroom ul li.nav-link a {
          padding-right: 1em; } }
      header.navigation.headroom ul li.nav-link a:hover {
        color: #f25022; }
      @media screen and (max-width: 54.375em) {
        header.navigation.headroom ul li.nav-link a {
          display: block; } }

header.navigation li.more.nav-link {
  padding-right: 0; }
  @media screen and (min-width: 54.375em) {
    header.navigation li.more.nav-link {
      padding-right: 1em; } }
  header.navigation li.more.nav-link > ul > li:first-child a {
    padding-top: 1em; }
  header.navigation li.more.nav-link a {
    margin-right: 1em; }
    @media screen and (max-width: 54.375em) {
      header.navigation li.more.nav-link a {
        margin-right: 0;
        padding: 0;
        text-align: center; } }
  header.navigation li.more.nav-link > a {
    padding-right: 0.6em; }
  header.navigation li.more.nav-link > a:after {
    position: absolute;
    top: auto;
    right: -0.4em;
    bottom: auto;
    left: auto;
    content: '\25BE';
    color: #ededf0; }

header.navigation li.more {
  overflow: visible;
  padding-right: 0; }
  header.navigation li.more a {
    padding-right: .8em; }
  header.navigation li.more > a {
    padding-right: 1.6em;
    position: relative; }
    @media screen and (min-width: 54.375em) {
      header.navigation li.more > a {
        margin-right: 1em; } }
    header.navigation li.more > a:after {
      content: '›';
      font-size: 1.2em;
      position: absolute;
      right: 0.5em; }
  header.navigation li.more:hover > .submenu {
    display: block; }
  @media screen and (min-width: 54.375em) {
    header.navigation li.more {
      padding-right: .8em;
      position: relative; } }

header.navigation ul.submenu {
  display: none;
  padding-left: 0; }
  @media screen and (min-width: 54.375em) {
    header.navigation ul.submenu {
      position: absolute;
      top: 2.5em;
      left: 0; } }
  @media screen and (min-width: 54.375em) {
    header.navigation ul.submenu .submenu {
      left: 11.8em;
      top: 0; } }
  header.navigation ul.submenu li {
    display: block;
    padding-right: 0; }
    @media screen and (max-width: 54.375em) {
      header.navigation ul.submenu li {
        padding-left: 0;
        text-align: center;
        border-bottom: solid 1px #191919; } }
    @media screen and (min-width: 54.375em) {
      header.navigation ul.submenu li {
        line-height: 3.5em; }
        header.navigation ul.submenu li:first-child > a {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px; }
        header.navigation ul.submenu li:last-child > a {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          padding-bottom: 1.2em; } }
    header.navigation ul.submenu li a {
      background-color: #252525;
      display: inline-block;
      text-align: right;
      width: 100%; }
      @media screen and (min-width: 54.375em) {
        header.navigation ul.submenu li a {
          padding: 0;
          text-align: center; } }
      @media screen and (min-width: 54.375em) {
        header.navigation ul.submenu li a {
          background-color: #2d2d2d;
          padding-left: 1em;
          text-align: left;
          width: 12em; } }

section.intro {
  padding: 0;
  color: white;
  text-transform: uppercase; }
  @media screen and (min-width: 30em) {
    section.intro .container {
      position: relative;
      top: 50%;
      transform: translateY(-50%); } }
  @media screen and (max-width: 30em) {
    section.intro .container {
      padding-top: 8em; } }
  section.intro h1 {
    text-align: center;
    color: white;
    padding-bottom: .5em; }
    section.intro h1 img {
      max-width: 50%; }
  section.intro h3 {
    text-align: center;
    font-size: 1.2em;
    padding: 0 20%; }
  section.intro a.jump-down {
    position: absolute;
    bottom: 4em;
    left: 20%;
    right: 20%;
    margin: 2em auto 0;
    display: block;
    width: 4em;
    text-align: center; }
    section.intro a.jump-down i {
      color: white;
      opacity: 0.5;
      font-size: 3.5em;
      text-align: center;
      transition: opacity 0.2s; }
      section.intro a.jump-down i:hover {
        opacity: 1; }

@media screen and (max-width: 54.375em) {
  #section-features {
    padding: 2em 0; } }

.features .title {
  color: #324655; }

.features .feature-item {
  padding: 1.5em 0;
  text-align: center; }
  @media screen and (min-width: 54.375em) {
    .features .feature-item {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%; }
      .features .feature-item:last-child {
        margin-right: 0; }
      .features .feature-item:nth-child(3n) {
        margin-right: 0; }
      .features .feature-item:nth-child(3n+1) {
        clear: left; } }
  @media screen and (max-width: 54.375em) {
    .features .feature-item {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%; }
      .features .feature-item:last-child {
        margin-right: 0; }
      .features .feature-item:nth-child(2n) {
        margin-right: 0; }
      .features .feature-item:nth-child(2n+1) {
        clear: left; } }
  @media screen and (max-width: 30em) {
    .features .feature-item {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%; }
      .features .feature-item:last-child {
        margin-right: 0; }
      .features .feature-item:nth-child(1n) {
        margin-right: 0; }
      .features .feature-item:nth-child(1n+1) {
        clear: left; } }
  .features .feature-item i {
    font-size: 2.8em;
    color: #3e6784;
    margin-bottom: .4em; }
  .features .feature-item h4 {
    text-transform: uppercase;
    color: #324655;
    font-size: 1.2em; }
  .features .feature-item p {
    color: #324655;
    font-size: 1em; }

#section-newsletter {
  padding: 9em 0 3em; }

.newsletter {
  padding: 3em 0 2em;
  color: #126d5f;
  background-color: #21c4ab; }
  .newsletter .nl-text,
  .newsletter .nl-form {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .newsletter .nl-text:last-child,
    .newsletter .nl-form:last-child {
      margin-right: 0; }
    @media screen and (max-width: 54.375em) {
      .newsletter .nl-text,
      .newsletter .nl-form {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        padding: 1em 5%; }
        .newsletter .nl-text:last-child,
        .newsletter .nl-form:last-child {
          margin-right: 0; } }
  .newsletter .nl-text p {
    margin-bottom: 0;
    font-weight: 700; }
  .newsletter .nl-form input[type="email"] {
    float: left;
    display: block;
    margin-right: 4.82916%;
    width: 65.05695%;
    padding: 0 1em;
    box-shadow: none;
    border: none !important;
    border-radius: 3px 0 0 3px;
    height: 3em;
    margin: 0; }
    @media screen and (max-width: 37.5em) {
      .newsletter .nl-form input[type="email"] {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 31.76157%;
        width: 100%;
        border-radius: 3px; }
        .newsletter .nl-form input[type="email"]:last-child {
          margin-right: 0; } }
    .newsletter .nl-form input[type="email"]:last-child {
      margin-right: 0; }
  .newsletter .nl-form input[type="submit"] {
    float: left;
    display: block;
    margin-right: 4.82916%;
    width: 30.11389%;
    margin-right: 0;
    height: 3em;
    border-radius: 0 3px 3px 0;
    background: #1a9885;
    text-transform: uppercase;
    margin: 0; }
    @media screen and (max-width: 37.5em) {
      .newsletter .nl-form input[type="submit"] {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 31.76157%;
        width: 100%;
        margin-top: 1em;
        border-radius: 3px; }
        .newsletter .nl-form input[type="submit"]:last-child {
          margin-right: 0; } }
    .newsletter .nl-form input[type="submit"]:last-child {
      margin-right: 0; }
    .newsletter .nl-form input[type="submit"]:hover {
      background: #1dae98; }

#section-page {
  padding: 9em 0 4em;
  min-height: 830px; }
  @media screen and (max-width: 54.375em) {
    #section-page {
      min-height: 0;
      padding: 8em 0; } }

.page {
  color: #ededf0;
  background-color: #324655; }
  .page .title {
    text-align: left;
    margin-bottom: 0; }
    @media screen and (max-width: 54.375em) {
      .page .title {
        text-align: center; } }
  .page a {
    text-decoration: underline; }
  .page a.button {
    text-decoration: none;
    margin: 0; }
  .page .main-column {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%; }
    .page .main-column:last-child {
      margin-right: 0; }
    @media screen and (max-width: 54.375em) {
      .page .main-column {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        text-align: center; }
        .page .main-column:last-child {
          margin-right: 0; } }
    .page .main-column h2 {
      color: #90b1c8;
      font-weight: 300;
      margin-top: 0.3em; }
    .page .main-column p {
      padding-right: 3em;
      padding-bottom: 0.5em; }
      @media screen and (max-width: 54.375em) {
        .page .main-column p {
          padding-right: 0; } }
  .page aside.sidebar {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .page aside.sidebar:last-child {
      margin-right: 0; }
    @media screen and (max-width: 54.375em) {
      .page aside.sidebar {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        padding: 2em 20% 0; }
        .page aside.sidebar:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 30em) {
      .page aside.sidebar {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        padding: 1em 3% 0; }
        .page aside.sidebar:last-child {
          margin-right: 0; } }
    .page aside.sidebar .widget-wrapper {
      margin-bottom: 3em; }
      .page aside.sidebar .widget-wrapper .widget-title {
        border-bottom: solid 4px #f2f2f2;
        margin-bottom: 0; }
        .page aside.sidebar .widget-wrapper .widget-title h4 {
          margin-bottom: 0; }
      .page aside.sidebar .widget-wrapper ul.blog-categories li {
        border-bottom: solid 1px #f2f2f2; }
        .page aside.sidebar .widget-wrapper ul.blog-categories li:before {
          content: '\f138';
          font-family: 'Fontawesome';
          float: left;
          padding: .5em;
          color: #999; }
        .page aside.sidebar .widget-wrapper ul.blog-categories li:hover:before {
          color: #21c4ab; }
        .page aside.sidebar .widget-wrapper ul.blog-categories li a {
          display: block;
          padding: .5em;
          color: #999;
          text-transform: uppercase;
          text-decoration: none; }
          .page aside.sidebar .widget-wrapper ul.blog-categories li a:hover {
            color: #21c4ab; }

#section-full-page {
  padding: 9em 0 3em;
  min-height: 830px; }
  @media screen and (max-width: 54.375em) {
    #section-full-page {
      min-height: 0; } }

.full-page {
  color: #ededf0;
  background-color: #324655; }
  .full-page .title {
    text-align: left;
    margin-bottom: 0; }
    @media screen and (max-width: 54.375em) {
      .full-page .title {
        text-align: center; } }
  .full-page .main-column {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    .full-page .main-column:last-child {
      margin-right: 0; }
    @media screen and (max-width: 54.375em) {
      .full-page .main-column {
        text-align: center; } }
    .full-page .main-column h2 {
      color: #93adc0;
      font-weight: 300; }
    .full-page .main-column h3.blog-date {
      color: #ededf0;
      font-weight: 300;
      font-size: 0.75em;
      margin-bottom: 0; }
    .full-page .main-column a.blog-link {
      font-size: 1.3em;
      padding-left: 1em; }

.pricing {
  padding-right: 1.5em; }
  .pricing .price-item {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    margin-bottom: 1.7em;
    text-align: center;
    font-size: 1.2em;
    color: #324655; }
    .pricing .price-item:last-child {
      margin-right: 0; }
    .pricing .price-item:nth-child(3n) {
      margin-right: 0; }
    .pricing .price-item:nth-child(3n+1) {
      clear: left; }
    @media screen and (max-width: 54.375em) {
      .pricing .price-item {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        padding-left: 1.5em; }
        .pricing .price-item:last-child {
          margin-right: 0; }
        .pricing .price-item:nth-child(1n) {
          margin-right: 0; }
        .pricing .price-item:nth-child(1n+1) {
          clear: left; } }
    .pricing .price-item .price-item-1 {
      color: #ededf0;
      background-color: #00aced; }
    .pricing .price-item .price-item-1-alt {
      background-color: #0092c9; }
    .pricing .price-item .price-item-2 {
      color: #ededf0;
      background-color: #1abc9c; }
    .pricing .price-item .price-item-2-alt {
      background-color: #169d82; }
    .pricing .price-item .price-item-3 {
      color: #ededf0;
      background-color: #c0a16b; }
    .pricing .price-item .price-item-3-alt {
      background-color: #b59152; }
    .pricing .price-item .price-item-heading {
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 0.5em 0.5em 0; }
    .pricing .price-item .price-item-price {
      font-size: 1.5em;
      font-weight: 600;
      color: #324655;
      padding-top: 0.3em;
      padding-right: 0.5em;
      padding-left: 0.5em;
      padding-bottom: 0.4em; }
    .pricing .price-item ul.price-item-list {
      font-size: 0.7em; }
      .pricing .price-item ul.price-item-list li.price-item-list-item-odd {
        background-color: #ededf0;
        padding-left: 0.3em;
        padding-right: 0.3em; }
      .pricing .price-item ul.price-item-list li.price-item-list-item-even {
        background-color: #dadae0;
        padding-left: 0.3em;
        padding-right: 0.3em; }
    .pricing .price-item .price-item-cta {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0.5em; }
    .pricing .price-item a.button {
      font-size: 0.9em; }

#section-showcase {
  padding: 6em 0 4em; }
  @media screen and (max-width: 54.375em) {
    #section-showcase {
      padding: 2em 0; } }

.showcase {
  padding: 1em 0 5em; }
  .showcase .split {
    margin: 5em 0 0 0; }
    .showcase .split::after {
      clear: both;
      content: "";
      display: table; }
    .showcase .split .split-left,
    .showcase .split .split-right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      color: #ededf0; }
      .showcase .split .split-left:last-child,
      .showcase .split .split-right:last-child {
        margin-right: 0; }
      @media screen and (max-width: 54.375em) {
        .showcase .split .split-left,
        .showcase .split .split-right {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 100%;
          padding: 1em 3em;
          text-align: center;
          float: none !important; }
          .showcase .split .split-left:last-child,
          .showcase .split .split-right:last-child {
            margin-right: 0; } }
      @media screen and (max-width: 30em) {
        .showcase .split .split-left,
        .showcase .split .split-right {
          padding: 10px; } }
      .showcase .split .split-left h1,
      .showcase .split .split-right h1 {
        font-size: 2.6em;
        margin: 0 0 0.3em;
        text-transform: none; }
      .showcase .split .split-left p.buttons,
      .showcase .split .split-right p.buttons {
        text-align: center; }
        @media screen and (min-width: 54.375em) {
          .showcase .split .split-left p.buttons,
          .showcase .split .split-right p.buttons {
            padding-right: 1.5em; } }
    .showcase .split .split-left.float-right {
      float: right; }
      @media screen and (min-width: 54.375em) {
        .showcase .split .split-left.float-right.split-text {
          padding-left: 1.5em; } }
    .showcase .split .split-right.float-left {
      float: left; }
    .showcase .split .split-media #showcase-slider .item img {
      display: block;
      width: 100%;
      height: auto;
      cursor: move; }
    .showcase .split .img {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 23.23176%;
      margin-bottom: 1em; }
      .showcase .split .img:last-child {
        margin-right: 0; }
      .showcase .split .img:nth-child(5n) {
        margin-right: 0; }
      .showcase .split .img:nth-child(5n+1) {
        clear: left; }

.showcase.gray {
  background: #f2f2f2; }

section.quote {
  padding: 3em 0.25em; }
  section.quote blockquote {
    text-align: center;
    font-style: normal;
    color: #bdf4eb;
    font-size: 1.5em;
    margin: 0;
    border: 0;
    padding-left: 0; }
    section.quote blockquote cite {
      padding-top: 1em;
      font-size: 0.9em;
      display: block;
      color: #bdf4eb; }

section.ticker {
  text-align: center;
  padding: 4.4em 0.25em; }
  @media screen and (max-width: 54.375em) {
    section.ticker {
      display: none; } }
  section.ticker h1 {
    color: white;
    font-family: "Share Tech Mono", monospace;
    font-size: 2.5em;
    letter-spacing: -0.05em;
    margin: 0; }
  section.ticker .ticker {
    padding-left: 0.2em;
    color: #324655; }

#section-contact {
  padding: 9em 0 3em;
  color: #ededf0;
  background-color: #324655; }
  @media screen and (max-width: 54.375em) {
    #section-contact {
      min-height: 0; } }
  #section-contact p {
    font-weight: 300; }

.contact .title {
  text-align: left;
  margin-bottom: 0; }
  @media screen and (max-width: 54.375em) {
    .contact .title {
      text-align: center; } }
  .contact .title h2 {
    padding-bottom: .4em; }

.contact .form::after {
  clear: both;
  content: "";
  display: table; }

.contact .form .form-left {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;
  padding-right: 1.65em;
  font-size: 1.4em;
  line-height: 1.33em; }
  .contact .form .form-left:last-child {
    margin-right: 0; }
  @media screen and (max-width: 54.375em) {
    .contact .form .form-left {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      padding: 0 5%;
      text-align: center; }
      .contact .form .form-left:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 37.5em) {
    .contact .form .form-left {
      font-size: 1.2em; } }

.contact .form .form-right {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%; }
  .contact .form .form-right:last-child {
    margin-right: 0; }
  @media screen and (max-width: 54.375em) {
    .contact .form .form-right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      padding: 0 5%; }
      .contact .form .form-right:last-child {
        margin-right: 0; } }

.contact .form label {
  display: none; }

.contact .form input {
  height: 3em;
  margin-bottom: 1em;
  padding: 0 1em; }

.contact .form textarea {
  padding: 1em;
  height: 12em; }

.contact p.submit {
  text-align: center;
  display: block; }
  .contact p.submit input {
    background: #f25022;
    width: 160px;
    height: 50px;
    text-transform: uppercase; }
    .contact p.submit input:hover {
      background: #f3633a; }

.contact .form-feedback {
  padding: 1em 2em;
  color: white; }

.contact .form-feedback.form-success {
  background-color: #2ecc71; }

.contact .form-feedback.form-error {
  background-color: #e74c3c; }

.contact #contact-error {
  margin: 0 20% 2em; }
  @media screen and (max-width: 37.5em) {
    .contact #contact-error {
      margin: 0 5% 2em; } }

#section-social-contacts {
  padding: 3.35em 0; }

.social-contacts {
  color: #ededf0;
  background-color: #21c4ab;
  font-family: "Coda", cursive; }
  .social-contacts p {
    font-size: 1.7em;
    color: #bdf4eb;
    text-align: center;
    margin-bottom: 0; }

footer.footer {
  position: relative;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #2d2d2d;
  z-index: -100; }
  footer.footer p.copyright {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding: 2em 0;
    color: #bfbfbf;
    margin-bottom: 0; }
    @media screen and (max-width: 37.5em) {
      footer.footer p.copyright {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        text-align: center;
        padding: 2em 0 0; }
        footer.footer p.copyright:last-child {
          margin-right: 0; } }
    footer.footer p.copyright:last-child {
      margin-right: 0; }
  footer.footer ul.social {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    float: right;
    text-align: right;
    margin: 2em auto; }
    @media screen and (max-width: 37.5em) {
      footer.footer ul.social {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%;
        text-align: center;
        padding: 0; }
        footer.footer ul.social:last-child {
          margin-right: 0; } }
    footer.footer ul.social:last-child {
      margin-right: 0; }
    footer.footer ul.social li {
      display: inline-block;
      text-align: center;
      list-style: none;
      color: #cccccc;
      transition: all 0.2s; }
      footer.footer ul.social li a {
        font-size: 1.3em;
        padding: 0 0 0 1.2em;
        color: #cccccc; }
        footer.footer ul.social li a:hover {
          color: #21c4ab; }
