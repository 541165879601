#section-showcase {
	padding: 6em 0 4em;
	@include media($large-screen-down) {
		padding: 2em 0;
	}
}
.showcase {
	padding: 1em 0 5em;
	.split {
		@include clearfix;
		margin: 5em 0 0 0;
		.split-left,
		.split-right {
			@include span-columns(6);
			@include media($large-screen-down) {
				@include span-columns(12);
				@include pad(1em 3em);
				text-align: center;
				float: none !important;
			}
			@include media($small-screen-down) {
				@include pad(10px);
			}
			color: $ourlite;
			h1 {
				font-size: 2.6em;
				margin: 0 0 0.3em;
				text-transform: none;
			}
			p.buttons {
				text-align: center;
				@include media($large-screen-up) {
					padding-right: 1.5em;
				}
			}
		}
		.split-left.float-right {
			float: right;
			&.split-text {
				@include media($large-screen-up) {
					padding-left: 1.5em;
				}
			}
		}
		.split-right.float-left {
			float: left;
		}
		.split-media {
			#showcase-slider .item img{
			    display: block;
			    width: 100%;
			    height: auto;
			    cursor: move;
			}
		}
		.img {
			@include span-columns(3);
			@include omega(5n);
			margin-bottom: 1em;
		}
	}
}
.showcase.gray {
	background: darken(white, 5%);
}