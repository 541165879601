#section-full-page {
	padding: 9em 0 3em;
	min-height: 830px;
	@include media($large-screen-down) {
		min-height: 0;
	}
}
.full-page {
	color: $ourlite;
	background-color: $ourmid;
	.title {
		text-align: left;
		margin-bottom: 0;
		@include media($large-screen-down) {
			text-align: center;
		}
	}
	.main-column {
		@include span-columns(12);
		@include media($large-screen-down) {
			text-align: center;
		}
		h2 {
			color: lighten($ourmid, 40%);
			font-weight: 300;
		}
		h3.blog-date {
			color: $ourlite;
			font-weight: 300;
			font-size: 0.75em;
			margin-bottom: 0;
		}
		a.blog-link {
			font-size: 1.3em;
			padding-left: 1em;
		}
	}
}