section.ticker {
	@include media($large-screen-down) {
		display: none;
	}
	text-align: center;
	padding: 4.4em 0.25em;
	h1 {
		color: white;
		font-family: $monospace;
		font-size: 2.5em;
		letter-spacing: -0.05em;
		margin: 0;
	}
	.ticker {
		padding-left: 0.2em;
		color: $ourmid;
	}
}