.pricing {
	padding-right: 1.5em;
	.price-item {
		@include span-columns(4);
		@include omega(3n);
		margin-bottom: 1.7em;

		@include media($large-screen-down) {
			@include span-columns(12);
			@include omega(1n);
			padding-left: 1.5em;
		}

		text-align: center;
		font-size: 1.2em;
		color: $ourmid;

		.price-item-1 {
			color: $ourlite;
			background-color: #00aced;
		}

		.price-item-1-alt {
			background-color: darken(#00aced, 7%);
		}

		.price-item-2 {
			color: $ourlite;
			background-color: #1abc9c;
		}

		.price-item-2-alt {
			background-color: darken(#1abc9c, 7%);
		}

		.price-item-3 {
			color: $ourlite;
			background-color: #c0a16b;
		}

		.price-item-3-alt {
			background-color: darken(#c0a16b, 7%);
		}

		.price-item-heading {
			text-transform: uppercase;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			padding: 0.5em 0.5em 0;
		}

		.price-item-price {
			font-size: 1.5em;
			font-weight: 600;
			color: $ourmid;
			padding-top: 0.3em;
			padding-right: 0.5em;
			padding-left: 0.5em;
			padding-bottom: 0.4em;
		}

		ul.price-item-list {
			font-size: 0.7em;
			li.price-item-list-item-odd {
				background-color: $ourlite;
				padding-left: 0.3em;
				padding-right: 0.3em;
			}
			li.price-item-list-item-even {
				background-color: darken($ourlite, 7%);
				padding-left: 0.3em;
				padding-right: 0.3em;
			}
		}

		.price-item-cta {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			padding: 0.5em;
		}

		a.button {
			font-size: 0.9em;
		}
	}
}