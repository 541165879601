section.quote {
	padding: 3em 0.25em;
	blockquote {
		text-align: center;
		font-style: normal;
		color: lighten($highlight, 40%);
		font-size: 1.5em;
		margin: 0;
		border: 0;
		padding-left: 0;
		cite {
			padding-top: 1em;
			font-size: 0.9em;
			display: block;
			color: lighten($highlight, 40%);
		}
	}
}