#section-newsletter {
	padding: 9em 0 3em;
}
.newsletter {
	padding: 3em 0 2em;
	color: darken($highlight, 20%);
	background-color: $highlight;
	.nl-text,
	.nl-form {
		@include span-columns(6);
		@include media($large-screen-down) {
			@include span-columns(12);
			@include pad(1em 5%);
		}
	}
	.nl-text {
		p {
			margin-bottom: 0;
			font-weight: 700;
		}
	}
	.nl-form {
		input[type="email"] {
			@include media($medium-screen-down) {
				@include span-columns(4);
				width: 100%;
				border-radius: 3px;
			}
			@include span-columns(4 of 6);
			padding: 0 1em;
			box-shadow: none;
			border: none !important;
			border-radius: 3px 0 0 3px;
			height: 3em;
			margin: 0;
		}
		input[type="submit"] {
			@include media($medium-screen-down) {
				@include span-columns(4);
				width: 100%;
				margin-top: 1em;
				border-radius: 3px;
			}
			@include span-columns(2 of 6);
			@include omega;
			height: 3em;
			border-radius: 0 3px 3px 0;
			background: darken($highlight, 10%);
			text-transform: uppercase;
			margin: 0;
			&:hover {
				background: darken($highlight, 5%);
			}
		}
	}
}