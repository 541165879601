#section-page {
	padding: 9em 0 4em;
	min-height: 830px;
	@include media($large-screen-down) {
		min-height: 0;
		padding: 8em 0;
	}
}
.page {
	color: $ourlite;
	background-color: $ourmid;
	.title {
		text-align: left;
		margin-bottom: 0;
		@include media($large-screen-down) {
			text-align: center;
		}
	}
	a {
		text-decoration: underline;
	}
	a.button {
		text-decoration: none;
		margin: 0;
	}
	.main-column {
		@include span-columns(9);
		@include media($large-screen-down) {
			@include span-columns(12);
			text-align: center;
		}
		h2 {
			color: $highlight5;
			font-weight: 300;
			margin-top: 0.3em;
		}
		p {
			padding-right: 3em;
			padding-bottom: 0.5em;
			@include media($large-screen-down) {
				padding-right: 0;
			}
		}
	}
	aside.sidebar {
		@include span-columns(3);
		@include media($large-screen-down) {
			@include span-columns(12);
			@include pad(2em 20% 0);
		}
		@include media($small-screen-down) {
			@include span-columns(12);
			@include pad(1em 3% 0);
		}
		.widget-wrapper {
			margin-bottom: 3em;
			.widget-title {
				h4 {
					margin-bottom: 0;
				}
				border-bottom: solid 4px darken(white, 5%);
				margin-bottom: 0;
			}
			ul.blog-categories li {
				border-bottom: solid 1px darken(white, 5%);
				&:before {
					content: '\f138';
					font-family: 'Fontawesome';
					float: left;
					padding: .5em;
					color: $medium-gray;
				}
				&:hover:before {
					color: $highlight;
				}
				a {
					display: block;
					padding: .5em;
					color: $medium-gray;
					text-transform: uppercase;
					text-decoration: none;
					&:hover {
						color: $highlight;
					}
				}
			}
		}
	}
}