/* DAP stuff */
$ourdark: #2d2d2d;
$ourmid:  #324655;
$ourlite: #ededf0;

/* typography */
body {
	font-family: 'Montserrat', sans-serif;
	color: $ourlite;
}

h1, h2, h3, h4, h5 {
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
}

p.lead {
	font-size: 1.5em;
	color: $highlight5;
	font-family: $cursive;
}

blockquote {
	font-style: italic;
	color: darken(white, 30%);
	font-size: 1.1em;

	p {
		margin:0;
	}
}

a {
	color: $highlight;
	&:hover {
		color: darken($highlight, 5%);
	}
}

input, 
textarea, 
button {
	font-family: $sans-serif !important;
}

input[type="text"], 
input[type="email"],
textarea {
	color: darken(white, 40%);
	border: solid 2px darken(white, 8%) !important;
	box-shadow: none !important;

	@include placeholder {
	    color: darken(white, 25%);
	}

	&:focus {
		border: solid 2px darken(white, 20%) !important;
	}
} 

/* layout */
.container {
	@include outer-container;
	padding: 0 .5em;
}

html, body, section.intro, .split, .split-text {
	height: 100%;
}

section {
	padding: 4em 0;
	position: relative;
}

.serif {
	font-family: $serif;
}

em {
	color: $highlight;
	font-family: $serif;
}

code {
	color: lighten($ourmid, 40%);
	font-family: $monospace;
	font-size: 1.1em;
}

kbd {
	color: $highlight3;
	font-family: $cursive;
	font-size: 1.1em;
}

.centered {
	text-align: center;
}

.no-transformation {
	text-transform: none;
}

.italic {
	font-style: italic;
}

.monospace {
	font-family: $monospace;
}

.color-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: $highlight;
	z-index: -100;
}

.dark {
	background-color: $ourdark;
}

.mid {
	background-color: $ourmid;
}

.lite {
	background-color: $ourlite;
}

.highlight {
	background-color: $highlight;
}

.deemphasize {
	font-size: 0.9em;
}
.superscript {
	position: relative;
	font-size: 50%;
	max-width:5%;
	vertical-align: text-top;
	top: 0;
	margin-top:0;
	padding-top:0;
}

/* title section */
.title {
	font-size: 1.5em;
	text-align: center;
	margin-bottom: 2em;
	color: $ourlite;
	h2 {
		text-transform: none;
	}
	hr {
		border: solid 2px rgba(0,0,0,0.1);
		margin: 1em auto 1.5em;

		&.small {
			width: 50px;	
		}
	}
	p {
		opacity: 0.5;
		font-size: 1.25em
	}
}

/* buttons */
a.button.large {
	color: $blue;
	background-color: $ourlite;
	text-transform: uppercase;
	text-align: center;
	padding: 1em 0.2em;
	margin-top: 1em;
	margin-right: 0.5em;
	margin-left: 0.5em;
	margin-bottom: 1em;
	width: 200px;
	height: 50px;
	&:hover {
		background: mix($blue, $ourlite, 30%);
	}
}

a.button.alt,
a.button.large.alt {
	color: $ourlite;
	background-color: $highlight2;
	&:hover {
		background: lighten($highlight2, 10%);
	}
}

/* owl carousel styles */
.owl-theme .owl-dots .owl-dot span {
	height: 14px;
	width: 14px;
}

.owl-theme .owl-nav [class*='owl-'] {
	padding-top: 17px;
	padding-right: 16px;
	padding-left: 16px;
	padding-bottom: 16px;
}

.owl-carousel {
	cursor: move;
}

/* flexslider */
.flexslider {
	margin: 0;
}

.flex-control-nav {
	bottom: 0;
	z-index: 100;

	li a {
		background: white;
		opacity: 0.5;
		height: 14px;
		width: 14px;
		box-shadow: none;

		&:hover {
			opacity: 0.7;
		}

		&.flex-active {
			background: white;
			opacity: 1;
		}
	}
}

.flex-direction-nav a {
	height: 52px;
	width: 52px;
	top: 45%;
	font-weight: bold;
	box-shadow: none;
	text-shadow: none;
	color: white;

	&:before {
		font-family: 'Fontawesome';
		font-size: 3em; 
		display: inline-block; 
		content: '\f104'; 
	}

	&.flex-next:before { 
		content: '\f105'; 
	}
}